"use strict";
import slider from "./modules/slider";
window.addEventListener("load", () => {
  slider();

  const mapButton = document.querySelector('.map__button');
  const infoMap = document.querySelector('.map__opacity');
  mapButton.addEventListener('click', () => {
    infoMap.classList.toggle('map__opacity--active');
    if(infoMap.classList.contains('map__opacity--active')) {
      mapButton.textContent = 'скрыть информацию';
    } else {
      mapButton.textContent = 'подробная информация';
    }
  });

});
// window.addEventListener('touchmove', function(event) {
//   event = event.originalEvent || event;

//   if(event.scale > 1) {
//       event.preventDefault();
//   }
// }, false);

// window.addEventListener("mousewheel", function(e) {
//   if (e.ctrlKey) {
//     e.preventDefault();
//     return false;
//   }
// });
// const body = document.querySelector('body');
// body.onwheel = function(event) {
//   if(event.ctrlKey) {
//     return false;
//   }
// }